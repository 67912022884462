import logoLink from '../resources/reflex.svg';

export const cssVariables = {
    active: '#84bd3f',
    inactive: '#CCC',

    primary_light: '#cee5b2',
    primary: '#84bd3f',
    primary_dark: '#6fa033',
    text_on_primary: '#fff',
    primary_transparent: '#e6f2d8',

    secondary: '#000',
    secondary_light: '#eceded',
    text_on_secondary: 'white',
    secondary_transparent: '#F7F7F7',

    font_color: 'black',
    link_color: '#84bd3f',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#84bd3f',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#000',    

    dropdown_background: '#cee5b2',

};

export const logo = logoLink;
